






































































































import { mapActions, mapState } from 'vuex'
import ButtonConfirm from '@/shared/components/layout/ButtonConfirm.vue'


import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'selector-card',
  components: {ButtonConfirm},
  data() {
    return {
      fab: false,
      deleteDialog: false,
    }
  },
  computed: {
    ...mapState(['layout']),
  },
  props: {
    title: {
      type: String,
      default: '...',
    },
    subTitle: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: Array,
      default: null,
    },
    compressed: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    clipboardData: {
      type: Object,
      default: null,
    },
    clipboardDataType: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    pasteTarget: {
      type: Array,
      default: null,
    },
    dynamic: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapActions(['setClipboard']),
    expand(e) {
      const selectorCard = e.target
      const cardTitle = selectorCard.firstChild
      const span = cardTitle.children[0]
      const selectorWidth = span.clientWidth
      const padding = parseInt(window.getComputedStyle(cardTitle, null).getPropertyValue('padding-left').slice(0, -2))
      const spanLastChild = span.children[span.children.length - 1]
      const actualTextLength = spanLastChild ? (spanLastChild.offsetLeft + spanLastChild.offsetWidth - padding) : 0
      const isActive = selectorCard.classList.contains('active') && selectorCard.classList.contains('has-actions')

      selectorCard.classList.add('hovering')

      if (Math.ceil(selectorWidth) + (isActive ? 30 : 1) < Math.floor(actualTextLength)) {
        setTimeout(() => {
          if (selectorCard.classList.contains('hovering')) {
            selectorCard.style.width = actualTextLength + padding * 2 + 30 + 'px'
          }
        }, 200)
      }
    },
    reset(e) {
      e.target.classList.remove('hovering')
      setTimeout(() => {
        if (!e.target.classList.contains('hovering')) {
          e.target.style.width = null
        }
      }, 400)
    },
  },
  mounted() {
  },
})
