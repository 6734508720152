<template>
  <v-btn
    rounded
    color="primary"
    :small="small"
    @click="$emit('click')"
  >
    {{ label || 'Save' }}
  </v-btn>
</template>
<script lang="js">
export default {
  name: 'ButtonPrimary',
  props:{
    label: {
      type: String,
      default: null,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
