var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"selection-card",class:{
    active:_vm.active,
    'has-actions': _vm.actions,
    'py-2 mb-2': _vm.compressed && !_vm.noMargin,
    'py-3 mb-4': !_vm.compressed && !_vm.noMargin,
    'mb-0 py-2': _vm.noMargin && _vm.compressed,
    'mb-0 py-3': _vm.noMargin && !_vm.compressed
  },attrs:{"id":_vm.id,"dark":_vm.active,"hover":_vm.hover,"elevation":"3","color":(_vm.$vuetify.theme.dark && !_vm.active) ? 'grey darken-4' : ''},on:{"mouseenter":function($event){_vm.dynamic ? _vm.expand : null},"mouseleave":function($event){_vm.dynamic ? _vm.reset : null}}},[_c('v-layout',{staticClass:"card-title px-5 ma-0",attrs:{"align-center":""}},[(_vm.actions && _vm.active)?_c('div',{staticStyle:{"position":"absolute"}},[_c('v-speed-dial',{attrs:{"direction":"right"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{staticClass:"dial",attrs:{"color":"white","dark":"","fab":"","text":"","small":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" close ")]):_c('v-icon',[_vm._v(" settings ")])],1)]},proxy:true}],null,false,3700197835),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.actions.includes('save'))?_c('v-btn',{staticClass:"save-button",attrs:{"fab":"","small":"","color":"primary"},nativeOn:{"click":function($event){return _vm.$emit('save')}}},[_c('v-icon',[_vm._v("save")])],1):_vm._e(),(_vm.actions.includes('delete'))?_c('button-confirm',{attrs:{"absolute":false,"right":false,"top":false},on:{"confirmed":function($event){return _vm.$emit('delete')}}}):_vm._e()],1)],1):_vm._e(),(_vm.icon)?_c('universal-icon',{staticClass:"mr-3",attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.title)?_c('strong',{style:({'margin-left': _vm.actions && _vm.active ? '40px' : '0'}),domProps:{"innerHTML":_vm._s(_vm.title.split('<br>').join(' ↵ '))}}):_vm._e(),(_vm.subTitle)?_c('span',{staticClass:"grey--text ml-3",domProps:{"innerHTML":_vm._s(_vm.subTitle)}}):_vm._e()],1),_c('v-container',{staticClass:"card-arrow pa-0",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" keyboard_arrow_right ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }